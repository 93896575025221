import React from "react";
import { Input } from "formik-antd";

import { FormFieldWrapper } from "../../../../components/private";
import { useLocale, useMobile } from "../../../../hooks";

const FbidField = React.memo(() => {
  const isSmallDevice = useMobile(true);
  const t = useLocale("posts/form");

  return (
    <FormFieldWrapper
      span={isSmallDevice ? 24 : 12}
      name="fbid"
      className="FbidField"
      placeholder={t("fields.fbid.placeholder")}
    >
      <Input name="fbid" />
    </FormFieldWrapper>
  );
});

export default FbidField;
