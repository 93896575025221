/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/

import { types } from "mobx-state-tree";

import { PostListModel, PostModel } from "./models";
import { POSTS } from "../constants";
import { api } from "../utils";

const PostStore = types
  .model("PostStore", {
    activeTab: types.optional(
      types.union(
        types.literal("ACCEPTED"),
        types.literal("REJECTED"),
        types.literal("REMOVED"),
        types.literal("REPORTED"), //FIXME: what is reported in services
        types.literal("NOT_VERIFIED")
      ),
      "NOT_VERIFIED"
    ),
    postList: PostListModel,
    post: PostModel,
    fullScreenMode: types.optional(types.boolean, false),
  })
  .views((self) => ({
    getAllowedActions(
      options = { ignore: null, isSingleAction: true, status: null }
    ) {
      const source = POSTS[options.isSingleAction ? "actions" : "batchActions"];
      const ignores = options.ignore ? options.ignore.split("|") : source;

      return source.filter(
        (item) =>
          item.key !== (options.status || self.activeTab) &&
          !ignores.includes(item.key)
      );
    },
    getAction(options = { name: null, isSingleAction: true }) {
      const source = POSTS[options.isSingleAction ? "actions" : "batchActions"];
      return source.find((item) => item.key === options.name);
    },
    getStatus(data) {
      return api.get("Post", data);
    },
  }))
  .actions((self) => ({
    changeTab: (key) => {
      const fetch = () => self.postList.fetchInitial();

      self.activeTab = key;
      if (key === "REPORTED")
        self.postList.changeFilter({ isReported: true }, fetch);
      else self.postList.changeFilter({ status: self.getStatus(key) }, fetch);
    },
  }));

export default PostStore.create({
  activeTab: "NOT_VERIFIED",
  postList: {},
  post: {},
});
